<template>
  <div class="report-main">
    <div class="nav-list">
      <div :class="navActive === index ? 'nav-con' : 'nav-con nav-con-active'" v-for="(item,index) in navList" :key="index" @click="navClick(item,index)">
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class="div-scroll table-wrapper" >
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
          :pagination="pageParams"
          @change="pageGet"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-detail" style="margin-right: 10px" @click="handleDownload(row)">下载</span>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import Configs from "./config/index";
  import common from '@/assets/js/common';
  let mPage = MPage({
    pagePath: "/report/forms/pages",
    pageParams: {},
  });
  export default {
    mixins: [mPage],
    data(){
      let { tableColumns } = Configs(this);
      return{
        tableColumns,
        navList:[
          {value:0,name:'日报'},
          {value:1,name:'季报'},
          {value:2,name:'年报'},
          {value:3,name:'特殊事件报告'},
        ],
        navActive:0,
        pageParams:{
          pageSize:10,
          pageNum:0,
          total:0
        },
        pageData:[]
      }
    },
    created() {
    },
    methods:{
      navClick(data,i){
        this.navActive = i
        this.pageParams.reportFormsType = i+1;
        this.pageGet(true);
      },
      handleDownload(data) {
        this.$http.post('/report/forms/download', {}, {
          contentType:"application/x-www-form-urlencoded",
          responseType: "blob",
          params: {id:data.id}
        }).then(res => {
          common.blobDownLoad(res)
        })
      },
    }
  }
</script>

<style lang="less" scoped>
.report-main{
  width: 100%;
  height: 100%;
  .nav-list{
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: flex;
  }
  .nav-con{
    width: 160px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-left: 1px solid #1A67D0;
    border-right: 1px solid #1A67D0;
    border-top: 1px solid #1A67D0;
    color: #1A67D0;
    font-weight: bold;
  }
  .nav-con:hover{
    cursor: pointer;
  }
  .nav-con i{
    color: #1A67D0;
    margin-right: 5px;
    font-weight: bold;
    font-size: 20px;
  }
  .nav-con-active{
    color: #fff;
    background: #1A67D0;
    border-bottom: 1px solid #1A67D0;
  }
  .nav-con-active i{
    color: #fff;
  }
  .table-wrapper{
    padding: 20px;
    background: #fff;
    height: calc(100% - 50px);
  }
}
</style>
