export default function Configs(that) {
  const tableColumns = [
    {
      label: "文件名称",
      prop: "fileName",
      align: "center",
      show: true
    },
    {
      label: "文件大小",
      prop: "size",
      align: "center",
      show: true
    },
    {
      label: "创建时间",
      prop: "createTime",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    tableColumns
  };
}
